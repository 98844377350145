export const convertErrors = (errors) =>
  errors.map((error) => {
    // on BE we depend on the spring validation (and i18n) framework which supports only numeric arg indices
    // therefore, argument placeholders for errors can only be numeric indices starting with 0
    const args = {};
    if (error.arguments) {
      error.arguments.forEach((arg, index) => {
        args[index] = arg.value;
      });
    }
    return {
      ...error,
      code: error.messageKey,
      values: args,
    };
  });

export const setErrors = (errors, formApi) => {
  const modalErrors = [];
  if (errors) {
    errors.forEach((error) => {
      if (formApi && formApi.fieldExists(error.subject)) {
        formApi.setError(error.subject, error.message);
      } else {
        modalErrors.push({
          field: error.subject,
          code: error.code,
          values: error.values,
        });
      }
    });
  }
  return modalErrors;
};

/**
 * Get first error from list of errors provided by formAPI
 */
export const getFirstErrorField = (fields, errors) =>
  fields.find((field) => {
    const fieldSlices = field.split('.');
    let currentErrors = errors;

    const pattern = /(?<name>\w+)\[(?<index>'?\w+'?)]/;

    for (let i = 0; i < fieldSlices.length; i += 1) {
      if (fieldSlices[i].endsWith(']')) {
        // will transform e.g. salesTaxnumber[i] -> {groups : [name : salesTaxnumber, index: i]}
        const result = fieldSlices[i].match(pattern).groups;
        currentErrors = currentErrors?.[result.name]?.[result.index];
      } else {
        currentErrors = currentErrors[fieldSlices[i]];
      }

      if (!currentErrors) {
        return false;
      }
    }

    return true;
  });
