import { gql } from '@apollo/client';

export const SUBSCRIBE_TO_NEWSLETTER = gql`
  mutation subscribeNewsletter(
    $email: String
    $firstName: String
    $lastName: String
    $sourceId: String
    $timendoArea: String
  ) {
    subscribeNewsletter(
      email: $email
      firstName: $firstName
      lastName: $lastName
      sourceId: $sourceId
      timendoArea: $timendoArea
    )
  }
`;

export const SUBSCRIBE_TO_NEWSLETTER_CDM = gql`
  mutation subscribeNewsletterCDM(
    $email: String!
    $time: String!
    $firstName: String
    $lastName: String
    $socialAndPartnersConsent: Boolean
  ) {
    subscribeNewsletterCDM(
      email: $email
      time: $time
      firstName: $firstName
      lastName: $lastName
      socialAndPartnersConsent: $socialAndPartnersConsent
    )
  }
`;

export const SUBSCRIBE_ANONYMOUS_NEWSLETTER_CDM = gql`
  mutation subscribeAnonymousNewsletterCDM($data: AnonymousNewsletterData!) {
    subscribeAnonymousNewsletterCDM(data: $data)
  }
`;

export const SUBSCRIBE_LOGGED_IN_NEWSLETTER_CDM = gql`
  mutation subscribeLoggedInNewsletterCDM($data: LoggedInNewsletterData!) {
    subscribeLoggedInNewsletterCDM(data: $data)
  }
`;
