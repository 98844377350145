import { useCallback, useContext } from 'react';
import {
  SUBSCRIBE_TO_NEWSLETTER,
  SUBSCRIBE_ANONYMOUS_NEWSLETTER_CDM,
} from 'components/NewsletterForm/NewsletterForm.mutation';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import { useMutation } from '@apollo/client';
import {
  NewsletterFormData,
  LoginNewsletterData,
  AnonymousNewsletterData,
} from 'components/NewsletterForm/NewsletterForm.types';

import CountryContext from 'Providers/IntlProvider/CountryContext';
import { getCurrentDateInISOExtended } from 'modules/customer-account/utils/dateExtended';

export const getConsents = (agb: boolean, created: string, newsletter: boolean) => {
  const identifier = 'EMAIL';
  const source = 'WEBSHOP';
  const status = newsletter;
  const referer = window.location.href;
  const consentNewsletter = {
    consent: 'NEWSLETTER',
    identifier,
    status,
    referer,
    created,
    source,
  };

  const consentSocialPartner = {
    ...consentNewsletter,
    consent: 'SOCIALPARTNERS',
    status: agb,
  };
  return [consentNewsletter, consentSocialPartner];
};

export const getEsbVariables = (newsletterData: NewsletterFormData) => {
  const { email, sourceId, timendoArea, firstName, lastName } = newsletterData;
  return { email, sourceId, timendoArea, firstName, lastName };
};

export const getShopName = (shopName: string) => {
  if (shopName === 'xxxlesnina') {
    return 'lesnina';
  }
  if (shopName === 'momax') {
    return 'moemax';
  }
  return shopName;
};

export const getCdmVariables = (
  newsletterData: NewsletterFormData,
  shopName: string,
  languageCode: string,
  store: string,
) => {
  const { agb, email, firstName, lastName, newsletter } = newsletterData;
  const created = getCurrentDateInISOExtended();
  const consents = getConsents(!!agb, created, newsletter);
  const loginData: LoginNewsletterData = {
    consents,
  };

  if (firstName || lastName) {
    loginData.newsletter = {
      firstname: firstName?.trim(),
      lastname: lastName?.trim(),
      created,
    };
  }

  const anonymousnData: AnonymousNewsletterData = {
    country: languageCode.toUpperCase(),
    group: getShopName(shopName),
    store,
    created,
    email: {
      description: email?.trim(),
      created,
      confirmed: false,
    },
    ...loginData,
  };

  return { data: anonymousnData };
};

const useSubscribeNewsletter = () => {
  const {
    shopName,
    store,
    language: { languageCode },
  } = useContext<any>(CountryContext);

  const isCdmSubscribeEnabled = useFeatureFlagsEnabled('poseidon.newsletter.cdm.switch');
  const [
    subscribeEsb,
    { data: dataSubscribeEsb, error: errorSubscribeEsb, loading: loadingSubscribeEsb },
  ] = useMutation(SUBSCRIBE_TO_NEWSLETTER);

  const [
    subscribeAnonymousCdm,
    {
      data: dataSubscribeAnonymousCdm,
      error: errorSubscribeAnonymousCdm,
      loading: loadingSubscribeAnonymousCdm,
    },
  ] = useMutation(SUBSCRIBE_ANONYMOUS_NEWSLETTER_CDM);

  const loadingCdm = loadingSubscribeAnonymousCdm;
  const errorsCdm = errorSubscribeAnonymousCdm;
  const dataCdm = dataSubscribeAnonymousCdm?.subscribeAnonymousNewsletterCDM;

  const loading = isCdmSubscribeEnabled ? loadingCdm : loadingSubscribeEsb;
  const errors = isCdmSubscribeEnabled ? errorsCdm : errorSubscribeEsb;
  const data = isCdmSubscribeEnabled ? dataCdm : dataSubscribeEsb?.subscribeNewsletter;

  const handleSubmitEsb = useCallback(
    (newsletterData: NewsletterFormData) => {
      subscribeEsb({ variables: getEsbVariables(newsletterData) });
    },
    [subscribeEsb],
  );

  const handleSubmitCdm = useCallback(
    (newsletterData: NewsletterFormData) => {
      const variables = getCdmVariables(newsletterData, shopName, languageCode, store);
      subscribeAnonymousCdm({ variables });
    },
    [languageCode, shopName, store, subscribeAnonymousCdm],
  );

  const subscribeNewsletter = useCallback(
    async (newsletterData: NewsletterFormData) =>
      isCdmSubscribeEnabled ? handleSubmitCdm(newsletterData) : handleSubmitEsb(newsletterData),
    [isCdmSubscribeEnabled, handleSubmitCdm, handleSubmitEsb],
  );

  return {
    subscribeNewsletter,
    data,
    loading,
    errors,
  };
};

export default useSubscribeNewsletter;
