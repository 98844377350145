import React, { useRef, useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { Button, Content, Heading, Image, FeedbackCard } from '@xxxlgroup/hydra-ui-components';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { confetti } from '@xxxlgroup/hydra-icons';
import { CmsMedia } from 'graphql-types/generated/types-generated';
import { validateEmail } from 'utils/validation';
import { getFirstErrorField } from 'utils/form';
import { parseHydraImage } from 'utils/imageUtils';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useMessage from 'components/Message/useMessage';
import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'components/Layout';
import CmsContent from 'components/CmsContent';
import { Block, Field, Form } from 'components/Form';
import LegalSection from 'components/NewsletterForm/components/LegalSection';
import FieldFlag from 'components/Form/components/FieldFlag';

import useSubscribeNewsletter from 'components/NewsletterForm/useSubscribeNewsletter';

import styles from 'components/NewsletterForm/NewsletterForm.scss';

const EMAIL = 'email';
const NAME = 'firstName';
const SURNAME = 'lastName';
const TERMS_AND_CONDITIONS = 'agb';
const LEGAL_CONSENT = 'legalConsent';
const LEGAL_CONSENT_EXTENDED = 'legalConsentExtended';
const SUCCESS_VALUE = 'success';
const ERROR_VALUE = 'error';

interface NewsletterFormProps {
  /** anchor ID */
  anchorId?: string;
  /** activate checkbox look for legal questions */
  withActivatedCheckboxes?: boolean;
  /** background image */
  backgroundImage?: CmsMedia | null;
  /** benefits text */
  benefitsText?: string;
  /** text for the checkbox */
  checkboxText?: string;
  /** disable marketing checkbox */
  withDisabledMarketingCheckbox?: boolean;
  /** legal remark */
  legalRemark?: string;
  /** legal text */
  legalText: string;
  /** extended legal text */
  legalTextExtended?: string;
  /** Boolean to show border */
  hasBorder?: boolean;
  /** form subtitle */
  subtitle: string;
  /** form headline */
  title: string;
}

type NewsletterFormFields = {
  email: string;
  firstName?: string;
  lastName?: string;
  agb?: boolean;
};

const NewsletterFormNew = ({
  anchorId,
  withActivatedCheckboxes,
  backgroundImage = undefined,
  benefitsText,
  checkboxText,
  withDisabledMarketingCheckbox,
  legalRemark,
  legalText,
  legalTextExtended,
  hasBorder = true,
  subtitle,
  title,
}: NewsletterFormProps) => {
  const [dispatchState, setDispatchState] = useState<string | null>(null);
  const [firstErrorField, setFirstErrorField] = useState<string | null>(null);
  const [iconStyle, iconClassName] = pseudoIcon(confetti);
  const formApiRef = useRef<any>(null);
  const fieldsOrder = [
    EMAIL,
    NAME,
    SURNAME,
    TERMS_AND_CONDITIONS,
    LEGAL_CONSENT,
    LEGAL_CONSENT_EXTENDED,
  ];

  const isNewsletterEnabled = useFeatureFlagsEnabled('poseidon.newsletter.enabled');
  const [
    buttonText,
    gdprInfoText,
    asteriskDescription,
    errorText,
    successTitle,
    successSubtitle,
    successThankYouText,
    maintenanceText,
  ] = useMessage([
    'poseidon.newsletter.submit',
    'poseidon.newsletter.gdpr.info',
    'poseidon.newsletter.asterisk.info',
    'wxs.newsletter.error',
    'poseidon.newsletter.success.title',
    'poseidon.newsletter.success.subtitle',
    'poseidon.newsletter.success.thankyou',
    'poseidon.newsletter.maintenance.info',
  ]);

  const { subscribeNewsletter, data, loading, errors } = useSubscribeNewsletter();

  const handleSubmitForm = useCallback(
    (formFields: NewsletterFormFields) => {
      subscribeNewsletter({ ...formFields, newsletter: true });
    },
    [subscribeNewsletter],
  );

  useEffect(() => {
    if (errors) {
      setDispatchState(ERROR_VALUE);
    } else if (data) {
      setDispatchState(SUCCESS_VALUE);
    }
  }, [data, errors, loading]);

  const setFormApi = (formApi: unknown) => {
    formApiRef.current = formApi;
  };

  const onSubmitFailure = (errors: Record<string, unknown>) => {
    setFirstErrorField(getFirstErrorField(fieldsOrder, errors));
  };

  const renderImage = (backgroundImage: CmsMedia) => (
    <div className={styles.backgroundImageContainer}>
      <Image
        source={parseHydraImage(backgroundImage) ?? {}}
        className={styles.backgroundImage}
        useSrcSet
        sizes="50vw"
      />
    </div>
  );

  const renderMarketingCheckbox = () =>
    !withDisabledMarketingCheckbox && (
      <Field
        className={styles.checkboxText}
        contentClassName={styles.checkboxContent}
        type="checkbox"
        checkboxText={checkboxText}
        name={TERMS_AND_CONDITIONS}
        data-purpose="newsletter.agb"
        placeholderCode="wxs.newsletter.input.terms.placeholder"
        isFirstError={firstErrorField === TERMS_AND_CONDITIONS}
        size="full"
        isBlock
      />
    );

  const renderError = () => (
    <FeedbackCard variant="error" className={styles.errors}>
      <Content content={errorText} />
    </FeedbackCard>
  );

  const renderSuccess = () => (
    <>
      <Layout
        className={classnames(styles.container, {
          [styles.noImageContainer]: !backgroundImage || dispatchState === SUCCESS_VALUE,
          [styles.successContainer]: dispatchState === SUCCESS_VALUE,
          [styles.withBorder]: hasBorder,
        })}
      >
        <div
          data-testid="newsletter.SuccessContainer"
          className={classnames(styles.success, {
            [styles.noPadding]: !hasBorder,
          })}
        >
          <Content
            tag="p"
            content={successTitle}
            className={classnames(styles.successTitle, iconClassName)}
            style={iconStyle}
          />
          <Content tag="p" content={successSubtitle} className={styles.successSubtitle} />
          <Content tag="p" content={successThankYouText} className={styles.successThankyou} />
          {legalRemark && <Content tag="p" content={legalRemark} className={styles.legalRemark} />}
        </div>
        {backgroundImage && dispatchState !== SUCCESS_VALUE && renderImage(backgroundImage)}
      </Layout>
      <ErrorBoundary withStatusCode>
        <CmsContent hideError pageCode="newsletter-success" />
      </ErrorBoundary>
    </>
  );

  const renderForm = () => (
    <Layout
      anchorId={anchorId}
      className={classnames(backgroundImage ? styles.container : styles.containerLight, {
        [styles.withBorder]: hasBorder,
      })}
    >
      <Form<NewsletterFormFields>
        onSubmit={handleSubmitForm}
        getApi={setFormApi}
        onSubmitFailure={onSubmitFailure}
        className={classnames(backgroundImage ? styles.form : styles.formLight, {
          [styles.noPadding]: !hasBorder,
        })}
      >
        <div className={classnames({ [styles.heading]: !backgroundImage })}>
          <Heading
            level={2}
            content={title}
            className={classnames({ [styles.lightHeading]: !hasBorder })}
          />
          <Content
            tag="p"
            content={subtitle}
            className={backgroundImage ? styles.subtitle : styles.subtitleNoImage}
          />
          {!isNewsletterEnabled && (
            <FeedbackCard variant="warning">
              <Content content={maintenanceText} />
            </FeedbackCard>
          )}
          {!backgroundImage && benefitsText && (
            <Content tag="div" content={benefitsText} className={styles.benefitsText} />
          )}
          {!backgroundImage && legalRemark && (
            <Content tag="p" content={legalRemark} className={styles.legalRemark} />
          )}
        </div>
        <div className={classnames({ [styles.formSide]: !backgroundImage })}>
          <Block className={styles.formGrid}>
            {dispatchState === ERROR_VALUE && renderError()}
            <div className={styles.inputArea}>
              <Field
                className={styles.emailInput}
                data-purpose="newsletter.email"
                isFirstError={firstErrorField === EMAIL}
                labelCode="poseidon.newsletter.email.input.label"
                name={EMAIL}
                placeholderCode="poseidon.newsletter.email.input.placeholder"
                isRequired
                size="full"
                type="email"
                autoComplete="email"
                validators={[validateEmail()]}
              />
              <Field
                className={styles.optionalInput}
                isBlock
                data-purpose="newsletter.firstname"
                isFirstError={firstErrorField === NAME}
                flagCode="poseidon.newsletter.formfield.firstname"
                labelCode="poseidon.newsletter.firstname.input.label"
                name={NAME}
                placeholderCode="poseidon.newsletter.firstname.input.placeholder"
                autoComplete="given-name"
                size="full"
              />
              <Field
                className={styles.optionalInput}
                isBlock
                data-purpose="newsletter.lastname"
                isFirstError={firstErrorField === SURNAME}
                flagCode="poseidon.newsletter.formfield.lastname"
                labelCode="poseidon.newsletter.lastname.input.label"
                name={SURNAME}
                placeholderCode="poseidon.newsletter.lastname.input.placeholder"
                autoComplete="family-name"
                size="full"
              />
            </div>
            {!withActivatedCheckboxes && renderMarketingCheckbox()}
            <LegalSection
              activateCheckboxes={withActivatedCheckboxes}
              checkboxContentClass={styles.checkboxContent}
              checkboxTextClass={styles.checkboxText}
              errorField={firstErrorField}
              legalText={legalText}
              legalTextExtended={legalTextExtended}
            />
            {withActivatedCheckboxes && renderMarketingCheckbox()}
            <Button
              className={classnames(styles.submit, {
                [styles.checkboxFormSubmit]: withActivatedCheckboxes,
              })}
              data-purpose="newsletter.submit"
              loading={loading}
              type="submit"
              disabled={!isNewsletterEnabled}
            >
              {buttonText}
            </Button>
          </Block>
          <Content tag="p" content={gdprInfoText} className={styles.legalInfo} />
          {/* asterisk text only shown if more than one field is rendered */}
          <FieldFlag code="poseidon.newsletter.formfield.firstname">
            {() => <Content tag="p" content={asteriskDescription} className={styles.legalInfo} />}
          </FieldFlag>
          {backgroundImage && legalRemark && (
            <Content
              tag="p"
              content={legalRemark}
              className={classnames(styles.legalRemark, styles.noVerticalMargin)}
            />
          )}
        </div>
      </Form>
      {backgroundImage && renderImage(backgroundImage)}
    </Layout>
  );

  return dispatchState === SUCCESS_VALUE ? renderSuccess() : renderForm();
};

export default NewsletterFormNew;
