import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Expander, Content } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import { Field } from 'components/Form';

import styles from 'components/NewsletterForm/components/LegalSection/LegalSection.scss';

const LegalSection = (props) => {
  const LEGAL_CONSENT = 'legalConsent';
  const LEGAL_CONSENT_EXTENDED = 'legalConsentExtended';
  const [showMore, showLess] = useMessage(['accordion.showMore', 'accordion.showLess'], null, true);
  const {
    activateCheckboxes,
    checkboxContentClass,
    checkboxTextClass,
    legalText,
    legalTextExtended,
    errorField,
  } = props;

  const i18nExpander = useMemo(() => ({ showMore, showLess }), [showMore, showLess]);

  const renderInfoSection = () => (
    <>
      {legalText && <Content tag="p" content={legalText} className={styles.consentInformation} />}
      {legalTextExtended && (
        <div className={styles.consentInformation}>
          <Expander
            className={styles.expander}
            contentHeight="xs"
            i18n={i18nExpander}
            theme="coal"
            variant="text"
          >
            <Content className={styles.expandText} content={legalTextExtended} />
          </Expander>
        </div>
      )}
    </>
  );

  const renderCheckboxSection = () => (
    <>
      {legalText && (
        <Field
          className={checkboxTextClass}
          contentClassName={checkboxContentClass}
          type="checkbox"
          checkboxText={legalText}
          name={LEGAL_CONSENT}
          data-purpose="newsletter.legal.confirmation"
          isFirstError={errorField === LEGAL_CONSENT}
          size="full"
          isRequired
          isBlock
        />
      )}
      {legalTextExtended && (
        <Field
          className={checkboxTextClass}
          contentClassName={checkboxContentClass}
          type="checkbox"
          checkboxText={legalTextExtended}
          name={LEGAL_CONSENT_EXTENDED}
          data-purpose="newsletter.legal.confirmation.extended"
          isFirstError={errorField === LEGAL_CONSENT_EXTENDED}
          size="full"
          isBlock
        />
      )}
    </>
  );

  return !activateCheckboxes ? renderInfoSection() : renderCheckboxSection();
};

LegalSection.propTypes = {
  /** flag to activate checkbox design */
  activateCheckboxes: PropTypes.bool,
  /** checkbox content styling */
  checkboxContentClass: PropTypes.string,
  /** checkbox text styling */
  checkboxTextClass: PropTypes.string,
  /** error field */
  errorField: PropTypes.string,
  /** legal text */
  legalText: PropTypes.string,
  /** legal text extended */
  legalTextExtended: PropTypes.string,
};

LegalSection.defaultProps = {
  activateCheckboxes: false,
  checkboxContentClass: null,
  checkboxTextClass: null,
  errorField: null,
  legalText: null,
  legalTextExtended: null,
};

export default LegalSection;
